import { addOrUpdateUrlParameter, removeUrlParameter } from '../util/urlParameters'


export const initSortFilter = () => {
    const $sortFilterSelect = $('.js-sort-filter')
    const URL_PARAMETER = 'sort'

    if (!$sortFilterSelect.length) {
        removeUrlParameter(URL_PARAMETER)
        return
    }

    addOrUpdateUrlParameter(URL_PARAMETER, $sortFilterSelect.val())

    $sortFilterSelect.on('change', function optionSelected () {
        addOrUpdateUrlParameter(URL_PARAMETER, $(this).val())
        window.location.reload()
    })
}
