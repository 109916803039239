import './legacy/application'
import './legacy/classes'
import './legacy/template'
import './legacy/yendifvideoshare'


import { lazyLoad } from './util/lazyImages'
import { subMenu } from './util/subMenu'
import { registrationForm } from './util/registrationForm'
import { loadingOverlay } from './components/loading-overlay'
import { contactForm } from './util/contactForm'
import { showSubmissionFormAfterChecklist } from './components/submissionForm'
import { videoPlayer } from './components/video-player'
import { videoPlaylist } from './components/video-playlist'
import { bankSelection } from './components/bank-selection'
import { juryVoting } from './components/jury-voting'
import { initTabs } from './components/tabs'
import { initSortFilter } from './components/sortFilter'


const initApp = () => {
    initSortFilter()
    initTabs()
    lazyLoad()
    subMenu()
    registrationForm()
    loadingOverlay()
    contactForm()
    showSubmissionFormAfterChecklist()
    videoPlayer()
    videoPlaylist()
    bankSelection()
    juryVoting()
}

$(initApp)
