export const contactForm = () => {
    const $contactForm = document.querySelector('.wpcf7-form')

    if ($contactForm === null) {
        return
    }

    const $submitButton = $contactForm.querySelector('.wpcf7-submit')
    const $requiredFields = $contactForm.querySelectorAll('.wpcf7-validates-as-required')
    const $controlInputs = new Array($requiredFields.length)
    const $privacyField = $contactForm.querySelector('.wpcf7-acceptance input')
    const $emailField = $contactForm.querySelector('.wpcf7-validates-as-email')
    const $emailValidationErrorField = document.createElement('label')
    const $emailValidationErrorMessage = 'Bitte gib eine gültige E-Mail-Adresse ein'
    const $userNameField = $('input[name="username"]')
    const wpNonce = $('.js-nonce').attr('data-nonce')

    function checkIfTextFieldHasContent (textField) {
        if (textField.value.length > 0) {
            return true
        }

        return false
    }

    function checkIfPrivacyIsChecked () {
        if ($privacyField.checked === true) {
            return true
        }

        return false
    }

    function returnTypeOfTag (element) {
        if (element.type === 'text') {
            return 'text'
        }
        if (element.type === 'email') {
            return 'email'
        }
        if (element.type === 'textarea') {
            return 'textarea'
        }
        if (element.type === 'checkbox') {
            return 'checkbox'
        }

        return null
    }

    function setDefaultControlInputs () {
        for (let i = 0; i < $controlInputs.length; i += 1) {
            $controlInputs[i] = false
        }
    }

    function checkControlInputs () {
        for (let i = 0; i < $controlInputs.length; i += 1) {
            if ($controlInputs[i] === false) {
                return false
            }
        }

        return true
    }

    function validateEmail (email) {
        /* eslint-disable */
        const regEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        /* eslint-enable */

        return regEx.test(String(email).toLowerCase())
    }

    function setNotValidEmailErrorMessage () {
        $emailValidationErrorField.setAttribute('class', 'validation-error')
        $emailValidationErrorField.innerHTML = $emailValidationErrorMessage
        $emailField.parentNode.appendChild($emailValidationErrorField)
    }

    function removeNotValidEmailErrorMessage () {
        $emailField.parentNode.removeChild($emailValidationErrorField)
    }

    function setControlInput (field, index) {
        const $inputType = returnTypeOfTag($requiredFields[index])

        if ($inputType === 'checkbox') {
            if (checkIfPrivacyIsChecked()) {
                $controlInputs[index] = true
            } else {
                $controlInputs[index] = false
            }
        }
        if ($inputType === 'text' || $inputType === 'textarea') {
            if (checkIfTextFieldHasContent($requiredFields[index])) {
                $controlInputs[index] = true
            } else {
                $controlInputs[index] = false
            }
        }
        if ($inputType === 'email') {
            if (checkIfTextFieldHasContent($requiredFields[index])) {
                $controlInputs[index] = true
            } else {
                $controlInputs[index] = false
            }
            if (!validateEmail($emailField.value)) {
                setNotValidEmailErrorMessage()
            }
            if (validateEmail($emailField.value)) {
                removeNotValidEmailErrorMessage()
            }
        }
        if (!$inputType) {
            $controlInputs[index] = false
        }
    }

    if ($contactForm) {
        $submitButton.disabled = true

        setDefaultControlInputs()

        for (let index = 0; index < $requiredFields.length; index += 1) {
            $requiredFields[index].addEventListener('change', () => {
                setControlInput($requiredFields[index], index)

                if (checkControlInputs()) {
                    $submitButton.disabled = false
                } else {
                    $submitButton.disabled = true
                }
            })
        }

        $contactForm.addEventListener('submit', (e) => {
            if (!validateEmail($emailField.value)) {
                e.preventDefault()
                $emailField.focus()
            }
        })
    }

    if ($userNameField && wpNonce) {
        $.ajax({
            method: 'GET',
            url: '/wp-json/jcvideo/v1/logged-in-username/',
            data: { _wpnonce: wpNonce },
        }).done((username) => {
            if (username) {
                $userNameField.val(username).attr('type', 'hidden')
                $userNameField.after(`<span>${username}</span>`)
            }
        })
    }
}
