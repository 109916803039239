/* eslint-disable */

/**
 * @package     Joomla.Site
 * @subpackage  Templates.protostar
 * @copyright   Copyright (C) 2005 - 2014 Open Source Matters, Inc. All rights reserved.
 * @license     GNU General Public License version 2 or later; see LICENSE.txt
 * @since       3.2
 */

const switchToXsSize = 1024;

(function ($) {
    // Check mobile Version
    const mobile = (/iphone|ipad|ipod|nokia|android|blackberry|mini|windows\sce|palm/i.test(navigator.userAgent.toLowerCase()))

    function getUrlParameter(parameter) {
        const sPageURL = new URLSearchParams(window.location.search)
        return sPageURL.get(parameter)
    }

    $(() => {
        // Checkliste bei Upload
        function initUploadChecklist() {
            const $checklistButton = $('.js-checklist-button')
            const $checklistButtonDisabled = $('.js-checklist-button-disabled')
            $checklistButton.hide()

            $('.checklist .check').on('change', function () {
                if ($('.checklist .check:checked').length === $('.checklist .check').length) {
                    $checklistButton.show()
                    $checklistButtonDisabled.hide()
                } else {
                    $checklistButton.hide()
                    $checklistButtonDisabled.show()
                }

                $(this).parent().toggleClass('active')
            })
        }

        // Musik Angabe Video-Upload
        function initVideoUpload() {
            let numSongs = $('.js-song-row').length
            let musicUsed = 0
            const form = $('#yendifForm')
            const $songContainer = $('#add-songs')

            $songContainer.hide()
            $songContainer.find('input').removeAttr('required')

            $('input[type=radio][name=video_uses_music]').on('change', function () {
                if (this.value == 1) {
                    $songContainer.show()
                    $songContainer.find('input').attr('required', 'required')
                    $('#no-songs').hide()
                    musicUsed = 1
                } else if (this.value == 0) {
                    $songContainer.hide()
                    $songContainer.find('input').removeAttr('required')
                    $('#no-songs').show()
                    musicUsed = 0
                }
            })

            $('input[name=video_uses_music][checked="checked"]').trigger('change')
            $('.js-edit-thumbnail .acf-label').remove()

            $('.js-new-video-upload-button').on('click', function () {
                $(this).fadeOut(200, () => {
                    $('.js-new-video-upload').fadeIn()
                    $('.js-new-video-upload input').attr('required', 'required')
                })
            })

            $(document).on('click', '.delete-song', function () {
                $(this).parent().remove()
            })

            $('#add-song').on('click', () => {
                numSongs++
                let songRow = $('#song-row-1').html()
                songRow = songRow.replace('name="song-title-1"', `name="song-title-${numSongs}"`)
                songRow = songRow.replace('name="song-artist-1"', `name="song-artist-${numSongs}"`)
                songRow = songRow.replace('name="song-source-1"', `name="song-source-${numSongs}"`)

                $('.song-list-wrapper').append(`<div class="song-row" id="song-row-${numSongs}">${songRow}<span class="delete-song">×</span></div>`)

                $(`[name="song-title-${numSongs}"]`).val('')
                $(`[name="song-artist-${numSongs}"]`).val('')
                $(`[name="song-source-${numSongs}"]`).val('')

                // Rebind the delete button to new song rows
                $(document).on('click', '.delete-song', function () {
                    $(this).parent().remove()
                })
            })

            form.on('submit', (e) => {
                let songs = []
                if (musicUsed) {
                    $('.song-row').each(function () {
                        const songTitle = $(this).find('input[name^=song-title]').val()
                        const songArtist = $(this).find('input[name^=song-artist]').val()
                        const songSource = $(this).find('input[name^=song-source]').val()

                        if (!songTitle) {
                            e.preventDefault()
                            $(this).find('input[name^=song-title]').addClass('invalid')
                        } else {
                            $(this).find('input[name^=song-title]').removeClass('invalid')
                        }

                        if (!songArtist) {
                            e.preventDefault()
                            $(this).find('input[name^=song-artist]').addClass('invalid')
                        } else {
                            $(this).find('input[name^=song-artist]').removeClass('invalid')
                        }

                        if (!songSource) {
                            e.preventDefault()
                            $(this).find('input[name^=song-source]').addClass('invalid')
                        } else {
                            $(this).find('input[name^=song-source]').removeClass('invalid')
                        }

                        if (songTitle && songArtist && songSource) {
                            const song = {
                                title: songTitle,
                                artist: songArtist,
                                source: songSource,
                            }
                            songs.push(song)
                        }
                    })
                } else {
                    songs = []
                }

                const json = JSON.stringify(songs)
                $('input[name=music]').val(json)
            })
        }

        // Registrierung

        let banksForFilter // für am Wettbewerb teilnehmenden Banken

        let ajax
        // Felder Person
        const fieldsPerson = new Array('#cbfr_46', '#cbfr_48', '#cbfr_55', '#cbfr_57', '#cbfr_58', '#cbfr_59', '#cbfr_42', '#cbfr_51', '#cbfr_51', '#cbfr_51__verify', '#cbfr_69', '#cbfr_50', '#cbfr_76')
        const fieldsPersonRequired = new Array('firstname', 'lastname', 'cb_streethousenumber', 'address_zip', 'cb_city', 'username', 'cb_age', 'password', 'password__verify', 'email', 'cb_terms')


        // Felder Gruppe
        const fieldsGroup = new Array('#cbfr_46', '#cbfr_48', '#cbfr_55', '#cbfr_57', '#cbfr_58', '#cbfr_42', '#cbfr_51', '#cbfr_51', '#cbfr_51__verify', '#cbfr_69', '#cbfr_50', '#cbfr_76', '#cbfr_74', '#cbfr_72', '#cbfr_73', '#cbfr_60')
        const fieldsGroupRequired = new Array('firstname', 'lastname', 'cb_streethousenumber', 'address_zip', 'cb_city', 'username', 'password', 'password__verify', 'email', 'cb_terms', 'cb_grname', 'cb_agefrom', 'cb_ageto')

        // Felder Voting
        const fieldsVote = new Array('#cbfr_42', '#cbfr_51', '#cbfr_51', '#cbfr_51__verify', '#cbfr_69', '#cbfr_50', '#cbfr_76')
        const fieldsVoteRequired = new Array('username', 'password', 'password__verify', 'email', 'cb_terms')

        // Wechsel des Typs (Person oder Gruppe)
        function changeRegistrationType(toShow) {
            if (toShow == 'person') {
                $('.cbRegistration .hint-registration').hide()
                $('.cbRegistration #hint-person').show()
                $('#myBank').show()

                showRegistrationFields('person')

                $('#cb_isgroup').attr('value', 0)

                $('.cbRegistration .registration-hint').hide()
                $('.cbRegistration #hint-reg-video').show()
            }

            if (toShow == 'group') {
                $('.cbRegistration .hint-registration').hide()
                $('.cbRegistration #hint-group').show()
                $('#myBank').show()

                showRegistrationFields('group')

                $('#cb_isgroup').attr('value', 1)

                $('.cbRegistration .registration-hint').hide()
                $('.cbRegistration #hint-reg-video').show()
            }

            if (toShow == 'vote') {
                $('.cbRegistration .hint-registration').hide()
                $('.cbRegistration #hint-vote').show()
                $('#myBank').hide()

                showRegistrationFields('vote')

                $('#cb_isgroup').attr('value', 2)

                $('.cbRegistration .registration-hint').hide()
                $('.cbRegistration #hint-reg-vote').show()
            }
        }


        // Felder je Typ anzeigen
        function showRegistrationFields(type) {
            // Formular nach wechsel resetten
            $('.cbRegistration .cb_form_line').hide()
            $('.cbRegistration *').removeClass('cbValidationError')
            $('.cbRegistration *').removeClass('cbValidationErrortext')
            $('.cbRegistration *').removeClass('cbValidationErrortextarea')
            $('.cb_result_warning').remove()
            $('.cbRegistration .cb_form_line input').removeClass('required')
            $('.cbRegistration .cb_form_line select').removeClass('required')
            $('.cbRegistration .cb_form_line textarea').removeClass('required')
            $('.mandatory').remove()

            if (type == 'person') {
                fieldsPerson.forEach(showRegistrationField)
                fieldsPersonRequired.forEach(setMadatoryFields)
            }

            if (type == 'group') {
                fieldsGroup.forEach(showRegistrationField)
                fieldsGroupRequired.forEach(setMadatoryFields)
            }

            if (type == 'vote') {
                fieldsVote.forEach(showRegistrationField)
                fieldsVoteRequired.forEach(setMadatoryFields)
            }
        }

        // Feld einblenden
        function showRegistrationField(elem) {
            $(elem).show()
        }

        // Pflichtfelder aktivieren
        function setMadatoryFields(elem) {
            $(`#${elem}`).addClass('required')
            $(`#${elem}`).parent().parent().parent()
                .find('label')
                .append('<span class="mandatory">*</span>')
        }

        // Pasweort vergessen Seite initialisieren
        function initLostPassword() {
            $('#cb_lost_username_passwd_content').prepend('<h1>Zugangsdaten vergessen?</h1>')
        }

        // User Profil bearbeiten initialisieren
        function initProfileChange() {
            $('.cbEditProfile').prepend('<h1>Profil bearbeiten</h1>')

            const accountType = $('#cb_isgroup').attr('value')

            $('#cbtab11 .cb_form_line').hide()

            if (accountType == 0) {
                fieldsPerson.forEach(showRegistrationField)
                fieldsPersonRequired.forEach(setMadatoryFields)
            }

            if (accountType == 1) {
                fieldsGroup.forEach(showRegistrationField)
                fieldsGroupRequired.forEach(setMadatoryFields)
            }

            if (accountType == 2) {
                fieldsVote.forEach(showRegistrationField)
                fieldsVoteRequired.forEach(setMadatoryFields)
            }

            $('#password').removeClass('required')
            $('#password__verify').removeClass('required')
            $('#cblabpassword .mandatory').remove()
            $('#cblabpassword__verify .mandatory').remove()

            $('#cbimg_upload_avatar').hide()
            $('#cbimg_gallery_avatar').hide()

            $('#avatar__choice option[value="gallery"]').remove()

            $('#avatar__choice').on('change', function () {
                const choice = $(this).attr('value')

                switch (choice) {
                    case 'upload':
                        $('#cbimg_upload_avatar').show()
                        $('#cbimg_gallery_avatar').hide()
                        break
                    case 'gallery':
                        $('#cbimg_upload_avatar').hide()
                        $('#cbimg_gallery_avatar').show()
                        break
                    default:
                        $('#cbimg_upload_avatar').hide()
                        $('#cbimg_gallery_avatar').hide()
                }
            })

            const validator = $('#cbcheckedadminForm').validate()
            validator.element('.required')
        }

        // Label für Nutzungsbedingungen und Newsletter hinzufügen
        function extraMarkupForRegisterAndProfileEdit() {
            $('#cb_newsletter').parent().append('<label for="cb_newsletter" class="hint">Ich bin damit einverstanden, per E-Mail über Neuigkeiten rund um den Video-Wettbewerb informiert zu werden. (Jederzeit widerrufbar)</label>')
            $('#cb_terms').parent().append('<label for="cb_terms" class="hint"><a target="_blank" href="/index.php/nutzungsbedingungen">Nutzungsbedingungen</a> akzeptieren</label>')
            $('#cblabcb_groupabout').append('<p class="field-hint">z.B. Name der Schule, Klassenarbeit, Film-AG</p>')
        }


        // Init Navigation
        function initNavigation() {
            const isMobile = checkXs()

            $('.main-navigation-wrapper .deeper').prepend('<span class="visible-xs mobile-sub-navi-trigger icon-caret-down"></span>')

            // For iPad touch interactions
            $('.run-on-ipad').on('touchstart', () => {
                const dimensions = getDimensions()
                if (dimensions !== 'portrait') {
                    $('#mobile-player').show()
                    $('#mobile-preview').hide()
                }
            })

            $('.run-on-ipad').on('touchstart', '.nav-header', (e) => {
                const dimensions = getDimensions()
                if (dimensions !== 'portrait') {
                    $('#mobile-player').hide()
                    $('#mobile-preview').show()
                    $('video').get(0).pause()
                    e.stopPropagation()
                }
            })

            $('.run-on-ipad #mobile-preview').on('touchstart', () => {
                $('#mobile-player').show()
                $('video').get(0).play()
            })

            // Navigation trigger actions
            $('#navigation-trigger').on('click', () => {
                $('.main-navigation-wrapper').addClass('navigation-open')
                $('#fader-navigation').toggleClass('invisible')
                $('video').hide()
            })

            $('#close-navigation-trigger, #fader-navigation').on('click', () => {
                $('.main-navigation-wrapper').removeClass('navigation-open')
                $('#fader-navigation').toggleClass('invisible')
                $('video').show()
            })

            // Prevent default click action in modal navigation
            $('#modal-navigation').on('click', 'span', (e) => {
                e.preventDefault()
            })
        }

        // Vote via Ajax
        function initVote() {
            $('.vote form').on('submit', (e) => {
                e.preventDefault()
                $(this).hide()

                const id = $('#stored-video-id').val()
                const user = $('#stored-user-id').val()

                $.ajax({
                    url: `/handle_votes.php?id=${id}&user=${user}`,
                    method: 'GET',  // Assuming it's a GET request, adjust if necessary
                }).done((data) => {
                    if (data === 'success') {
                        $('.vote').prepend('<button class="btn o-button--disabled">Stimme abgegeben</button>')
                        const votes = $('#num-votes').text()
                        const numvotes = parseInt(votes, 10) + 1
                        $('#num-votes').text(numvotes)
                        $('#num-votes-mob').text(numvotes)
                    } else {
                        $('.vote').prepend('<span>Leider gab es einen Fehler.</span>')
                    }
                })
            })
        }

        // Playlist
        function initPlaylist() {
            if ($('.video-playlist-home').length > 0) {
                $('.playlist-random-video-list .change-video-trigger:first-child').addClass('current')
                let currentVideo = 1
                const numVideos = $('.playlist-random-video-list .change-video-trigger').length

                $('.playlist-random-video-list').on('click', '.change-video-trigger', (e) => {
                    e.preventDefault()
                    $('.playlist-random-video-list li').removeClass('current')
                    $(this).addClass('current')

                    const videoSource = $(this).find('a').attr('href')
                    const videoPoster = $(this).find('a').data('poster')
                    const videoLink = $(this).find('a').data('video-detail')
                    currentVideo = $(this).data('num-video')

                    const videoPlayer = $('.playlist-player video').get(0)
                    videoPlayer.pause()
                    $('#mp4-source').attr('src', videoSource)
                    $('.playlist-player video').attr('poster', videoPoster)
                    $('.playlist-player video').attr('data-current-video', currentVideo)
                    videoPlayer.load()
                    videoPlayer.play()
                    $('.yf-poster').hide()
                    $('.yendifplayer').addClass('is-playing').removeClass('is-paused')
                    $('.yf-controls, .yf-embedbtn').fadeIn()
                    $('.playlist-player .more-videos a').attr('href', videoLink)
                    $('#player-video-container').get(0).scrollIntoView()
                })

                $('#player-video-container')
                    .on('mouseleave', () => {
                        $('.yf-controls, .yf-embedbtn').fadeOut()
                    })
                    .on('mouseenter', function () {
                        if ($(this).hasClass('is-playing')) {
                            $('.yf-controls, .yf-embedbtn').fadeIn()
                        }
                    })
            }

            $('.playlist-player video').on('ended', () => {
                const currentVideo = $('.playlist-player video').attr('data-current-video')
                playNextPlaylistVideo(currentVideo)
            })
        }


        function playNextPlaylistVideo(currentVideo) {
            const numVideos = $('.playlist-random-video-list .change-video-trigger').length
            let nextVideo = parseInt(currentVideo) + 1

            if (nextVideo > numVideos) {
                nextVideo = 1
            }

            const videoItem = $(`#playlist-video-item-${nextVideo}`)
            const videoSource = videoItem.find('a').attr('href')
            const videoPoster = videoItem.find('a').attr('data-poster')
            const videoLink = videoItem.find('a').attr('data-video-detail')
            var currentVideo = videoItem.attr('data-num-video')

            $('.playlist-random-video-list li').removeClass('current')
            videoItem.addClass('current')

            $('.playlist-player video').get(0).pause()
            $('#mp4-source').attr('src', videoSource)
            $('.playlist-player video').attr('poster', videoPoster)
            $('.playlist-player video').attr('data-current-video', currentVideo)
            $('.playlist-player video').get(0).load()
            $('.playlist-player .more-videos a').attr('href', videoLink)

            if (nextVideo != 1) {
                $('.playlist-player video').get(0).play()
            }
        }

        // Init Contact-Form
        function initContactForm() {
            const validator = $('#contact-form').validate()

            $('.required input').each(() => {
                $(this).rules('add', {
                    messages: {
                        required: 'Dieses Feld ist ein Pflichtfeld',
                        email: 'Bitte gib eine gültige E-Mail-Adresse ein',
                    },
                })
            })

            $('.required textarea').each(() => {
                $(this).rules('add', {
                    messages: {
                        required: 'Dieses Feld ist ein Pflichtfeld',
                    },
                })
            })

            $('#jform_contact_email_privacy').on('change', function () {
                if ($(this).is(':checked')) {
                    $('#jform_contact_submit').removeAttr('disabled')
                } else {
                    $('#jform_contact_submit').attr('disabled', 'disabled')
                }
            })
        }

        function checkXs() {
            const windowWidth = $(window).width()

            if (windowWidth <= switchToXsSize) {
                return true
            }
            return false
        }

        function resizeVideoPlayer(width) {
            const height = width / 1.77777778
            $('.yendifplayer').css('width', `${width}px`)
            $('.yendifplayer').css('height', `${height}px`)
            $('#player-video-container video').css('width', `${width}px`)
            $('#player-video-container video').css('height', `${height}px`)

            const playListVideoThumbWidth = $('.playlist-random-video-list li figure').outerWidth()
            const playListVideoThumbHeight = playListVideoThumbWidth / 1.77777778
            $('.playlist-random-video-list li figure').css('height', `${playListVideoThumbHeight}px`)
        }

        function initVideoPlayer() {
            const isMobile = checkXs()
            if (isMobile == true) {
                var width = $('.content-area').outerWidth() - 20
                resizeVideoPlayer(width)
            } else {
                var width = 624
                resizeVideoPlayer(width)
            }
        }

        function initIpad() {
            $('#mobile-preview').hide()
        }

        $(window).resize(() => {
            if ($('.yendifplayer, #player-video-container').length > 0) {
                initVideoPlayer()
            }
        })

        // Funktionen initial ausführen
        if ($('.yendifplayer, #player-video-container').length > 0) {
            initVideoPlayer()
        }

        if ($('.cbEditProfile').length > 0) {
            extraMarkupForRegisterAndProfileEdit()
            initProfileChange()
            initBankSelection()
        }

        if ($('.cbRegistration').length > 0) {
            extraMarkupForRegisterAndProfileEdit()
        }

        if ($('#cb_lost_username_passwd_content').length > 0) {
            initLostPassword()
        }

        if ($('.vote form').length > 0) {
            initVote()
        }

        if ($('#contact-form').length > 0) {
            initContactForm()
        }

        if ($('.run-on-ipad .player').length > 0) {
            initIpad()
        }

        $('[data-toggle="tooltip"]').tooltip()

        initUploadChecklist()
        initNavigation()
        setDimensions()
        initVideoUpload()
        initPlaylist()
    })

    $(window).resize(() => {
        setDimensions()
    })

    function setDimensions() {
        const windowWidth = $(window).width()
        const windowHeight = $(window).height()

        if (windowWidth >= windowHeight) {
            $('html').attr('data-dimensions', 'landscape')
        } else {
            $('html').attr('data-dimensions', 'portrait')
        }
    }

    function getDimensions() {
        const dimensions = $('html').attr('data-dimensions')
        return dimensions
    }
}(jQuery))

/* eslint-enable */
