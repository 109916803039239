/* eslint-disable */
/*
 * @version		$Id: yendifvideoshare.js 1.0.0 26-02-2014 $
 * @package		Yendif Video Share
 * @copyright   Copyright (C) 2012-2013 MrVinoth
 * @license     GNU/GPL http://www.gnu.org/licenses/gpl-2.0.html
*/

let yendifMediaType = 'video'
const yendifFrames = []
let yendifFilesQueue = 0
const yendifLanguages = []
yendifLanguages.cancel = 'Cancel'
yendifLanguages.success = 'File successfully uploaded'
yendifLanguages.invalid_file_type = 'Invalid file format'
yendifLanguages.invalid_file_size = 'Invalid file size'
yendifLanguages.invalid_mime_type = 'Invalid mime type'
yendifLanguages.error_moving_file = 'Error moving file'
yendifLanguages.unknown_error = 'Unknown error'
yendifLanguages.retry = 'Retry upload'
yendifLanguages.reset = 'Reset'

function yendifChangeMediaType(type) {
    yendifMediaType = type
    yendifDisplay(['yendifMp4', 'yendifWebm', 'yendifOgg', 'yendifYoutube', 'yendifRtmp', 'yendifFlash', 'yendifMobile', 'yendifImage', 'yendifCaptions'], 'none')
    document.getElementById('yendif_hidden_type').value = type
    switch (type) {
        case 'video':
            yendifDisplay(['yendifMp4', 'yendifWebm', 'yendifOgg', 'yendifImage', 'yendifCaptions'], '')
            break
        case 'youtube':
            yendifDisplay(['yendifYoutube', 'yendifImage', 'yendifCaptions'], '')
            break
        case 'rtmp':
            yendifDisplay(['yendifRtmp', 'yendifFlash', 'yendifMobile', 'yendifImage', 'yendifCaptions'], '')
            break
    }
}

function yendifOnFileSelect(elem, name) {
    const mediaField = document.getElementById(`yendif_${name.toLowerCase()}`)
    if (elem.value == '') return false

    const uploadButton = document.getElementById(`yendif${name}Uploader`)
    uploadButton.style.display = 'none'

    const response = document.getElementById(`yendif${name}Result`)

    // Create the iframe...
    yendifFrames[name] = document.createElement('iframe')
    yendifFrames[name].setAttribute('id', `yendifUploadIframe${name}`)
    yendifFrames[name].setAttribute('name', `yendifUploadIframe${name}`)
    yendifFrames[name].setAttribute('width', '0')
    yendifFrames[name].setAttribute('height', '0')
    yendifFrames[name].setAttribute('border', '0')
    yendifFrames[name].setAttribute('style', 'width:0 height:0 border:none')

    // Add to document...
    const form = document.getElementById(`yendifUploadForm${name}`)
    form.parentNode.appendChild(yendifFrames[name])
    window.frames[`yendifUploadIframe${name}`].name = `yendifUploadIframe${name}`

    iframeId = document.getElementById(`yendifUploadIframe${name}`)

    // Add event...
    const eventHandler = function () {
        iframeId = document.getElementById(`yendifUploadIframe${name}`)

        if (iframeId.detachEvent) {
            iframeId.detachEvent('onload', eventHandler)
        } else {
            iframeId.removeEventListener('load', eventHandler, false)
        }

        // Message from server...
        let content = null

        if (iframeId.contentWindow && iframeId.contentWindow.document.body) {
            content = iframeId.contentWindow.document.body.innerHTML
        } else if (iframeId.document && iframeId.document.body) {
            content = iframeId.document.body.innerHTML
        } else if (iframeId.contentDocument && iframeId.contentDocument.body) {
            content = iframeId.contentDocument.body.innerHTML
        }

        --yendifFilesQueue

        if (content == '') content = 'unknown_error'
        if (/invalid_file_type|invalid_file_size|invalid_mime_type|error_moving_file|unknown_error/.test(content)) {
            response.innerHTML = `<span class="yendifUploadFailure">${yendifLanguages[content]} - <a class="yendifRetryUpload" href="javascript:void(0)" onclick="yendifResetUpload('${name}')return false">${yendifLanguages.retry}</a></span>`
            mediaField.value = ''
        } else {
            response.innerHTML = `<span class="yendifUploadSuccess">${yendifLanguages.success} - <a class="yendifRetryUpload" href="javascript:void(0)" onclick="yendifResetUpload('${name}')return false">${yendifLanguages.reset}</a></span>`
            document.getElementById(`yendif_${name.toLowerCase()}`).value = content
            jQuery('#upload-modal').modal('hide')
        }
    }

    if (iframeId.addEventListener) {
        iframeId.addEventListener('load', eventHandler, true)
        if (name == 'Mp4') {
            jQuery('#upload-modal').modal('show')
        }
    }

    if (iframeId.attachEvent) {
        iframeId.attachEvent('onload', eventHandler)
        if (name == 'Mp4') {
            jQuery('#upload-modal').modal('show')
        }
    }

    // Set properties of form...
    form.setAttribute('target', `yendifUploadIframe${name}`)
    form.setAttribute('action', `index.php?option=com_yendifvideoshare&view=upload&format=raw&f=${name.toLowerCase()}`)
    form.setAttribute('method', 'post')
    form.setAttribute('enctype', 'multipart/form-data')
    form.setAttribute('encoding', 'multipart/form-data')

    // Submit the form...
    form.submit()
    ++yendifFilesQueue

    uploadButton.style.display = 'none'
    response.innerHTML = `<span class="yendifUploading">${elem.value}<a class="yendifCancelUpload" href="javascript:void(0)" onclick="yendifAbortUpload('${name}')return false">${yendifLanguages.cancel}</a></span>`

    return false
}

function yendifAbortUpload(name) {
    iframeId = document.getElementById(`yendifUploadIframe${name}`)
    if (iframeId.contentWindow.stop) {
        iframeId.contentWindow.stop()
    } else {
        iframeId.contentWindow.document.execCommand('Stop')
    }

    --yendifFilesQueue

    document.getElementById(`yendif_${name.toLowerCase()}`).value = ''

    const uploadButton = document.getElementById(`yendif${name}Uploader`)
    uploadButton.style.display = 'inline-block'

    document.getElementById(`yendif${name}Result`).innerHTML = ''

    iframeId.parentNode.removeChild(iframeId)
}

function yendifResetUpload(name) {
    document.getElementById(`yendif_${name.toLowerCase()}`).value = ''

    const uploadButton = document.getElementById(`yendif${name}Uploader`)
    uploadButton.style.display = 'inline-block'

    document.getElementById(`yendif${name}Result`).innerHTML = ''

    iframeId = document.getElementById(`yendifUploadIframe${name}`)
    iframeId.parentNode.removeChild(iframeId)
}

function yendifLayoutMode(value) {
    yendifDisplay(['yendifRatio', 'yendifPlayerWidth', 'yendifPlayerHeight'], 'none')(value == 1) ? yendifDisplay(['yendifRatio'], '') : yendifDisplay(['yendifPlayerWidth', 'yendifPlayerHeight'], '')
}

function yendifComments(value) {
    const style = (value == 'facebook') ? '' : 'none'
    yendifDisplay(['yendifFacebookID', 'yendifFacebookPostCount', 'yendifFacebookColor'], style)
}

function yendifDisplay(elements, display) {
    for (let e = 0, el = elements.length; e < el; e++) {
        document.getElementById(elements[e]).style.display = display
    }
}

function yendifAddClass(elements, value) {
    for (let e = 0, el = elements.length; e < el; e++) {
        const elem = document.getElementById(elements[e])
        const rspaces = /\s+/
        const classNames = (value || '').split(rspaces)
        const className = ` ${elem.className} `
        let setClass = elem.className
        for (let c = 0, cl = classNames.length; c < cl; c++) {
            if (className.indexOf(` ${classNames[c]} `) < 0) {
                setClass += ` ${classNames[c]}`
            }
        }
        elem.className = setClass.replace(/^\s+|\s+$/g, '')
    }
}

function yendifRemoveClass(elements, value) {
    for (let e = 0, el = elements.length; e < el; e++) {
        const elem = document.getElementById(elements[e])
        const rspaces = /\s+/
        const rclass = /[\n\t]/g
        const classNames = (value || '').split(rspaces)
        let className = (` ${elem.className} `).replace(rclass, ' ')
        for (let c = 0, cl = classNames.length; c < cl; c++) {
            className = className.replace(` ${classNames[c]} `, ' ')
        }
        elem.className = className.replace(/^\s+|\s+$/g, '')
    }
}

function YendifRating(rating, videoid) {
    const userid = document.getElementById('yendif_userid').value
    const warning = document.getElementById('yendif_userid_warning').value
    if (userid == 0) {
        alert(warning)
        return false
    }

    document.getElementById('yendifRating').innerHTML = '<span class="yendifAjaxSpinner"></span>'
    let xmlhttp

    if (window.XMLHttpRequest) {
        xmlhttp = new XMLHttpRequest()
    } else {
        xmlhttp = new ActiveXObject('Microsoft.XMLHTTP')
    }

    xmlhttp.onreadystatechange = function () {
        if (xmlhttp.readyState == 4 && xmlhttp.status == 200) {
            if (xmlhttp.responseText) {
                document.getElementById('yendifRating').innerHTML = xmlhttp.responseText
            }
        }
    }

    xmlhttp.open('GET', `index.php?option=com_yendifvideoshare&view=ajax&format=raw&rating=${rating}&videoid=${videoid}`, true)
    xmlhttp.send()
}

function yendifRadioValue(elem) {
    let cnt = -1
    const buttons = document.getElementsByName(elem)
    const len = buttons.length - 1

    for (let i = len; i > -1; i--) {
        if (buttons[i].checked) { cnt = i; i = -1 }
    }

    if (cnt > -1) return buttons[cnt].value
    return null
}

/* eslint-enable */
