/* eslint-disable import/extensions */
import { isNilOrEmpty } from '@/util/functional'
/* eslint-enable import/extensions */

/* eslint-disable no-param-reassign */
const showImage = (lazyImage) => {
    if (isNilOrEmpty(lazyImage)) {
        return
    }

    if (lazyImage.dataset.src) {
        lazyImage.src = lazyImage.dataset.src
    }

    if (lazyImage.dataset.srcset) {
        lazyImage.srcset = lazyImage.dataset.srcset
    }
}

const lazyLoadElement = (lazyImage) => {
    if (lazyImage.tagName === 'PICTURE') {
        showImage(lazyImage.querySelector('img'))
        showImage(lazyImage.querySelector('source'))
    } else if (lazyImage.tagName === 'IMG') {
        showImage(lazyImage)
    }

    lazyImage.classList.remove('js-lazy')
}

/**
 * Function for lazy loading all images with class 'js-lazy' within the given
 * root element.
 *
 * @see https://developers.google.com/web/fundamentals/performance/lazy-loading-guidance/images-and-video/
 * @example
 * <img class="js-lazy" src="some-placeholder-img.jpg" data-src="large-image.jpg" >
 *
 * <picture class="js-lazy">
 *     <source srcset="some-placeholder-img.jpg" data-srcset="large-img.jpg" media="(min-width: 64em)">
 *     <img
 *         class="c-ad-carousel__image"
 *         src="some-placeholder-img.jpg"
 *         data-src="large-img.jpg"
 *         data-srcset="large-img.jpg"
 *         alt="some text"
 *     >
 * </picture>
 */
export const lazyLoad = async (root = document) => {
    const lazyImages = Array.from(root.querySelectorAll('.js-lazy'))

    const lazyImageObserver = new IntersectionObserver((entries) => {
        entries.forEach(({ isIntersecting, target }) => {
            if (isIntersecting) {
                lazyLoadElement(target)
                lazyImageObserver.unobserve(target)
            }
        })
    })

    lazyImages.forEach((img) => lazyImageObserver.observe(img))
}
