/* eslint-disable */

$(function () {
    $('.dropdown-toggle').dropdown()
    $('.collapse').collapse('show')
    $('#myModal').modal('hide')
    $('.tabs').button()
    $('.tip').tooltip()
    $(".alert-message").alert()
})

/* eslint-enable */
