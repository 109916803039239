/* eslint-disable */

// Add classes

document.addEventListener("DOMContentLoaded", function () {
    var input = document.querySelectorAll("input.button");
    input.forEach(function (el) {
        el.classList.replace('button', 'o-button');
    });

    var button = document.querySelectorAll("button.button");
    button.forEach(function (el) {
        el.classList.replace('button', 'o-button');
    });

    var p = document.querySelectorAll("p.readmore");
    p.forEach(function (el) {
        el.classList.replace('readmore', 'btn');
    });

    var table = document.querySelectorAll("table.category");
    table.forEach(function (el) {
        el.classList.replace('category', 'table table-striped');
    });

    var ulActions = document.querySelectorAll("ul.actions");
    ulActions.forEach(function (el) {
        el.classList.replace('actions', 'nav nav-pills');
    });

    var ulPagenav = document.querySelectorAll("ul.pagenav");
    ulPagenav.forEach(function (el) {
        el.classList.replace('pagenav', 'pagination');
    });
});


/* eslint-enable */
