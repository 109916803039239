export const registrationForm = () => {
    const form = document.querySelector('#cbcheckedadminForm')

    if (form === null) {
        return
    }

    const submitButton = form.querySelector('input[type=submit]')
    const formChoices = form.querySelectorAll('.select-registration-type-item input')
    const formGroup = form.querySelectorAll('.js-formgroup')
    const passwordField = document.getElementById('login_password')
    const passwordConfirmationField = document.getElementById('login_password_repeat')
    const mailField = document.getElementById('login_mail')
    const mailConfirmationField = document.getElementById('login_mail_repeat')
    const defaultErrorMessage = 'Dieses Feld ist ein Pflichtfeld.'
    const schoolFieldTriggers = form.querySelectorAll('.js-school-trigger')
    const schoolFields = form.querySelectorAll('.js-school-field')
    const appellationLabelsFormal = form.querySelectorAll('.js-appellation-formal')
    const appellationLabelsCasual = form.querySelectorAll('.js-appellation-casual')
    let invalidFields
    let errorMessage

    window.onload = () => {
        mailConfirmationField.onpaste = (e) => {
            e.preventDefault()
        }
    }

    /**
     * Switch between "Siezen" and "Duzen"
     * @param apellation
     */
    function switchAppellationTo (apellation) {
        const hideFormalLabels = apellation !== 'formal'
        const hideCasualLabels = apellation === 'formal'

        for (let i = 0; i < appellationLabelsFormal.length; i += 1) {
            appellationLabelsFormal[i].classList.toggle('u-hidden', hideFormalLabels)
        }

        for (let i = 0; i < appellationLabelsCasual.length; i += 1) {
            appellationLabelsCasual[i].classList.toggle('u-hidden', hideCasualLabels)
        }
    }

    function hideSchoolFields () {
        for (let i = 0; i < schoolFields.length; i += 1) {
            schoolFields[i].classList.add('u-hidden')
            schoolFields[i].getElementsByTagName('input')[0].classList.add('u-hidden')
        }

        removeRequiredAttributeForInvisibleFormFields() // eslint-disable-line no-use-before-define
    }

    function showSchoolFields () {
        for (let i = 0; i < schoolFields.length; i += 1) {
            schoolFields[i].classList.remove('u-hidden')
            schoolFields[i].getElementsByTagName('input')[0].classList.remove('u-hidden')
        }
        addRequiredAttributeToVisibleFormFields() // eslint-disable-line no-use-before-define
    }

    function resetSchoolTrigger () {
        for (let i = 0; i < schoolFieldTriggers.length; i += 1) {
            schoolFieldTriggers[i].checked = false
        }

        hideSchoolFields()
    }

    function removeAllActiveElements () {
        for (let i = 0; i < formChoices.length; i += 1) {
            formChoices[i].parentNode.classList.remove('select-registration-type-item-active')
        }
    }

    function closeAllForms () {
        for (let i = 0; i < formGroup.length; i += 1) {
            formGroup[i].classList.add('u-hidden')
        }
    }

    function removeRequiredAttributeForInvisibleFormFields () {
        const hiddenFields = form.querySelectorAll('textarea.js-formgroup.u-hidden, input.js-formgroup.u-hidden, select.u-hidden')
        for (let i = 0; i < hiddenFields.length; i += 1) {
            if (hiddenFields[i].hasAttribute('required')) {
                hiddenFields[i].removeAttribute('required')
                hiddenFields[i].setAttribute('data-required', true)
            }
        }
    }

    function addRequiredAttributeToVisibleFormFields () {
        const visibleFields = form.querySelectorAll('textarea.js-formgroup:not(.u-hidden), input.js-formgroup:not(.u-hidden)')
        for (let i = 0; i < visibleFields.length; i += 1) {
            if (visibleFields[i].hasAttribute('data-required')) {
                visibleFields[i].setAttribute('required', '')
            }
        }
    }

    function displaySelectedForm () {
        const formGroupPerson = form.querySelectorAll('.js-formgroup--person')
        const formGroupGroup = form.querySelectorAll('.js-formgroup--group')
        const formGroupVote = form.querySelectorAll('.js-formgroup--vote')
        const formGroupTeacher = form.querySelectorAll('.js-formgroup--teacher')
        const formTeacherAdressNotice = form.querySelectorAll('.js-teacher-address-notice')

        for (let i = 0; i < formChoices.length; i += 1) {
            formChoices[i].addEventListener('click', () => {
                closeAllForms()
                removeAllActiveElements()
                formChoices[i].parentNode.classList.add('select-registration-type-item-active')
                if (formChoices[i].dataset.regType === 'person') {
                    for (let index = 0; index < formGroupPerson.length; index += 1) {
                        formGroupPerson[index].classList.remove('u-hidden')
                    }
                    switchAppellationTo('casual')
                } else if (formChoices[i].dataset.regType === 'group') {
                    for (let index = 0; index < formGroupGroup.length; index += 1) {
                        formGroupGroup[index].classList.remove('u-hidden')
                    }
                    switchAppellationTo('casual')
                } else if (formChoices[i].dataset.regType === 'vote') {
                    for (let index = 0; index < formGroupVote.length; index += 1) {
                        formGroupVote[index].classList.remove('u-hidden')
                    }
                    switchAppellationTo('casual')
                } else if (formChoices[i].dataset.regType === 'teacher') {
                    for (let index = 0; index < formGroupTeacher.length; index += 1) {
                        formGroupTeacher[index].classList.remove('u-hidden')
                    }
                    switchAppellationTo('formal')
                    formTeacherAdressNotice[0].classList.remove('u-hidden')
                }
                resetSchoolTrigger()
                addRequiredAttributeToVisibleFormFields()
                removeRequiredAttributeForInvisibleFormFields()
            })
        }
    }

    function isPasswordConfirmed () {
        if (passwordField.value === passwordConfirmationField.value) {
            return true
        }

        return false
    }

    function isMailAdressConfirmed () {
        if (mailField.value === mailConfirmationField.value) {
            return true
        }

        return false
    }

    function replaceErrorMessages (indexOfInvalidField) {
        errorMessage = defaultErrorMessage

        if (invalidFields[indexOfInvalidField].classList.contains('login__input')) {
            errorMessage = 'Bitte gib mindestens 5 Zeichen ein.'
        } else if (invalidFields[indexOfInvalidField].classList.contains('email__input')) {
            errorMessage = 'Bitte gib eine gültige E-Mail-Adresse ein.'
        } else if (invalidFields[indexOfInvalidField].classList.contains('js-validate-password')) {
            errorMessage = 'Bitte gib mindestens 6 Zeichen ein.'
        } else if (invalidFields[indexOfInvalidField].classList.contains('js-validate-zip')) {
            errorMessage = 'Bitte gib eine gültige Postleitzahl ein.'
        } else if (invalidFields[indexOfInvalidField].classList.contains('js-validate-select')) {
            errorMessage = 'Bitte wähle eine Option aus.'
        }

        return errorMessage
    }

    function removeAllErrorMessages () {
        const allErrors = form.querySelectorAll('.cb_result_warning')
        for (let i = 0; i < allErrors.length; i += 1) {
            allErrors[i].parentNode.removeChild(allErrors[i])
        }
    }

    function addErrorMessagesToInvalidFields () {
        invalidFields = form.querySelectorAll(':invalid')
        for (let i = 0; i < invalidFields.length; i += 1) {
            errorMessage = replaceErrorMessages(i)
            invalidFields[i].parentNode.insertAdjacentHTML('beforeend', `<div class="cb_result_warning">${errorMessage}</div>`)
        }
        if (!isPasswordConfirmed()) {
            errorMessage = 'Bitte gib den selben Wert nochmal ein.'
            passwordConfirmationField.parentNode.insertAdjacentHTML('beforeend', `<div class="cb_result_warning">${errorMessage}</div>`)
            passwordConfirmationField.focus()
        }

        if (!isMailAdressConfirmed()) {
            errorMessage = 'Bitte gib 2x dieselbe E-Mail-Adresse ein.'
            mailConfirmationField.parentNode.insertAdjacentHTML('beforeend', `<div class="cb_result_warning">${errorMessage}</div>`)
            mailConfirmationField.focus()
        }
    }

    function focusOnFirstInvalidField () {
        if (invalidFields.length > 0) {
            invalidFields[0].focus()
        }
    }

    function replaceDefaultValidationBubbles () {
        form.addEventListener('invalid', (e) => {
            e.preventDefault()
        }, true)

        form.addEventListener('submit', function preventSubmissionOfInvalidForm (e) {
            if (!this.checkValidity() || !isPasswordConfirmed() || !isMailAdressConfirmed()) {
                e.preventDefault()
            }
        })

        submitButton.addEventListener('click', () => {
            removeAllErrorMessages()
            addErrorMessagesToInvalidFields()
            focusOnFirstInvalidField()
        })
    }

    for (let i = 0; i < schoolFieldTriggers.length; i += 1) {
        schoolFieldTriggers[i].addEventListener('change', (event) => {
            if (event.target.value === 'graduate' && event.target.checked) {
                hideSchoolFields()
            } else {
                showSchoolFields()
            }
        })
    }

    displaySelectedForm()
    replaceDefaultValidationBubbles(form)
}
