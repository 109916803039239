export const subMenu = () => {
    const $menuItems = $('.nav-header')
    const $allMenuChildren = $('.nav-child')
    const $navigationFader = $('.navigation__fader')

    function closeMenu () {
        $allMenuChildren.removeClass('open')
    }

    $navigationFader.addClass('invisible')

    $menuItems.on('click', function clickMenu (e) {
        e.stopPropagation()

        const $nextMenuChild = $(this).next($allMenuChildren)

        if ($nextMenuChild.hasClass('open')) {
            $nextMenuChild.removeClass('open')
        } else {
            $allMenuChildren.removeClass('open')
            $nextMenuChild.addClass('open')
        }
    })

    $('html').on('click', () => {
        closeMenu()
    })

    $(window).on('popstate', () => {
        closeMenu()
    })

    /**
     * Close navigation after any navigation link is clicked
     * Prevent leaving opened menu behind if going back in history
     */
    $('.nav .menu-item a').on('click', () => {
        $('#close-navigation-trigger').trigger('click')
    })
}
