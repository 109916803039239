export const showSubmissionFormAfterChecklist = () => {
    const $checklistButton = $('.js-checklist-button')
    const $checklist = $('.js-submission-checklist')
    const $submissonForm = $('.js-submission-form')
    const $videoImage = $('.js-submission-form-video-image')
    const validImageTypes = ['image/png', 'image/jpeg']

    $checklistButton.on('click', () => {
        $checklist.fadeOut(400, () => {
            $submissonForm.fadeIn(200)
        })

        $('body,html').animate({ scrollTop: 0 }, 600)
    })

    $videoImage.on('change', function validateVideoImage () {
        if ($.inArray(this.files[0].type, validImageTypes) < 0) {
            this.value = null
        }
    })
}
