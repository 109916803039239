import { addOrUpdateUrlParameter, removeUrlParameter } from '../util/urlParameters'


export const initTabs = () => {
    const urlParams = new URLSearchParams(window.location.search)
    const currentTab = urlParams.get('tab')
    const $tabPanes = $('.js-tab-pane')
    const $tabButtons = $('.js-tab-button')
    const $initialActiveTab = $('.js-tab-pane.active')
    const $submissionSort = $('.js-submission-sort')
    const BUTTON_PRIMARY_CLASS = 'o-button--primary'
    const BUTTON_LIGHT_CLASS = 'o-button--light'
    const URL_PARAMETER = 'tab'
    const TAB_WINNERS = 'winners'

    if (!$tabPanes.length) {
        removeUrlParameter(URL_PARAMETER)
        return
    }

    $tabPanes.hide()

    if (currentTab) {
        $(`#${currentTab}`).show()
        $tabButtons.removeClass(BUTTON_PRIMARY_CLASS)
        $tabButtons.addClass(BUTTON_LIGHT_CLASS)
        $(`*[data-tab="${currentTab}"]`).addClass(BUTTON_PRIMARY_CLASS)
        $(`*[data-tab="${currentTab}"]`).removeClass(BUTTON_LIGHT_CLASS)
    } else {
        const $initialActiveButton = $(`*[data-tab="${$initialActiveTab.attr('id')}"]`)
        $initialActiveTab.show()
        $initialActiveButton.addClass(BUTTON_PRIMARY_CLASS)
        addOrUpdateUrlParameter(URL_PARAMETER, $initialActiveTab.attr('id'))
    }

    const $activeButton = $(`.${BUTTON_PRIMARY_CLASS}`)
    $submissionSort.toggle(!$activeButton.data('hide-sort'))

    $tabButtons.on('click', function tabClick () {
        const tabName = $(this).data('tab')

        if (tabName === TAB_WINNERS) {
            removeUrlParameter(URL_PARAMETER)
            const modifiedUrl = `${window.location.href.split('/seite/')[0]}`
            window.location.href = modifiedUrl
        } else {
            $tabPanes.hide()
            $tabButtons.removeClass(BUTTON_PRIMARY_CLASS)
            $tabButtons.addClass(BUTTON_LIGHT_CLASS)
            $(this).addClass(BUTTON_PRIMARY_CLASS)
            $(this).removeClass(BUTTON_LIGHT_CLASS)
            $(`#${tabName}`).show()
            addOrUpdateUrlParameter(URL_PARAMETER, tabName)
            $submissionSort.toggle(!$(this).data('hide-sort'))
        }
    })
}
