const changeVideoInPlayer = ($videoPlayer, $clicked) => {
    const $videoThumbnail = $('.js-video-player-thumb')
    const videoToShow = $clicked.attr('data-video')

    if ($videoThumbnail.length === 0) {
        $('.js-video-iframe').remove()
        $videoPlayer.prepend(`<iframe class="js-video-iframe embeded-video" src="https://player.vimeo.com/video/${videoToShow}?autoplay=1" width="640" height="360" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>`)
    } else {
        const $thumbnailToShow = $clicked.find('img')
        $videoThumbnail.find('img').remove()
        $videoThumbnail.prepend($thumbnailToShow[0].outerHTML)
        $videoPlayer.attr('data-video', videoToShow)
    }

    $('.js-playlist-video-link').attr('href', $clicked.attr('data-link'))
}

export const videoPlaylist = () => {
    const $videoPlaylist = $('.js-video-playlist')
    const $videoPlayer = $('.js-video-player')

    if ($videoPlaylist.length === 0) {
        return
    }

    const $videoItem = $('.js-playlist-item')

    $videoItem.on('click', (e) => {
        e.preventDefault()
        const $clicked = $(e.delegateTarget)
        $videoItem.removeClass('c-video-playlist__list-item--current')
        $clicked.addClass('c-video-playlist__list-item--current')
        changeVideoInPlayer($videoPlayer, $clicked)
    })
}
