export const videoPlayer = () => {
    const $videoPlayer = $('.js-video-player')

    if ($videoPlayer.length === 0) {
        return
    }

    function setIframeToFullWidth () {
        const $embedVideo = $('.js-video-iframe')
        if ($embedVideo.length > 0) {
            const embedVideoHeight = $embedVideo.width() * 0.5625
            $videoPlayer.css('height', embedVideoHeight)
            $embedVideo.css('height', embedVideoHeight)
        }
    }

    $videoPlayer.on('click', (e) => {
        const videoToShow = $(e.delegateTarget).data('video')
        const $videoThumbnail = $(e.delegateTarget).find('.js-video-player-thumb')

        if ($videoThumbnail.length > 0) {
            $videoThumbnail.remove()
            $(e.delegateTarget).prepend(`
                <iframe class="js-video-iframe embeded-video" src="https://player.vimeo.com/video/${videoToShow}?autoplay=1" width="640" height="360" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
            `)
            setIframeToFullWidth()
        }
    })

    $(window).on('resize', () => {
        setIframeToFullWidth()
    })
}

