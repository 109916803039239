/**
 * Returns a function, that, as long as it continues to be invoked, will not
 * be triggered. The function will be called after it stops being called
 * for N milliseconds. If `immediate` is passed, trigger the function on the
 * leading edge, instead of the trailing.
 *
 * @sig (Number, Func, Boolean) -> Func
 * @see https://davidwalsh.name/javascript-debounce-function
 * @example
 *
 * elem.addEventListener('keyup', debounce((e) => {
 *     // this will be executed 300ms after user stops typing
 * }, 300))
 */
export function debounce (fn, delay) {
    let timeoutID = null

    return function doDebounce (...args) {
        clearTimeout(timeoutID)
        const that = this

        timeoutID = setTimeout(() => fn.apply(that, args), delay)
    }
}
