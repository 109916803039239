import { debounce } from '../util/debounce'


export const bankSelection = () => {
    const $addressZip = $('.js-address-zip')
    const $schoolZip = $('.js-school-zip')
    const $schoolFieldTriggers = $('.js-school-trigger')
    const $bankSelection = $('.js-bank-selection')
    const $bankPlaceholder = $('.js-bank-placeholder')
    const $bankLoading = $('.js-bank-loading')

    if ($schoolZip.length === 0) {
        return
    }

    let previousRequest = null
    let previousZip = null

    const updateVisibility = (state) => {
        $bankSelection.toggle(state === 'success')
        $bankSelection.prop('required', state === 'success')
        $bankLoading.toggle(state === 'loading')
        $bankPlaceholder.toggle(state === 'error')

        if (state !== 'success') previousZip = null
    }

    const updateBankSelection = debounce(() => {
        const schoolParticipant = $schoolFieldTriggers.filter(':checked').val() || false
        const isTeacher = $('.js-reg-type--teacher')[0].checked
        const isStudent = (schoolParticipant !== 'graduate' && !isTeacher)
        let zipCode = $addressZip.val()

        if (isStudent) {
            zipCode = $schoolZip.val()
        }

        // Prevent triggering a request for obviously invalid data.
        if ((!isTeacher && !schoolParticipant) || !zipCode.match(/^\d{5}$/)) {
            updateVisibility('error')
            return
        }

        // Prevent trigering a request for unchanged zip
        if (zipCode === previousZip) {
            return
        }

        $bankSelection.find('option').remove()
        updateVisibility('loading')

        previousZip = zipCode

        // Cancel the previous request to make sure it doesn't conflict with the new request
        if (previousRequest) {
            previousRequest.abort()
        }
        previousRequest = $.ajax({
            type: 'get',
            url: '/index.php',
            data: {
                vr_api_action: 'valid_banks',
                zip: zipCode,
            },
            success: (result) => {
                if (result !== 'error') {
                    try {
                        const banks = JSON.parse(result)
                        $bankSelection.append('<option disabled selected hidden value="">--- Bitte auswählen ---</option>')
                        banks.forEach((bank) => {
                            $bankSelection.append($('<option>').val(bank.bank_code).text(bank.name))
                        })
                        updateVisibility('success')

                        return
                    } catch (e) {
                        console.error(e)
                    }
                }

                updateVisibility('error')
            },
            error: () => updateVisibility('error'),
        })
    }, 500)

    $schoolZip.on('keyup', updateBankSelection)
    $schoolZip.on('change', updateBankSelection)
    $addressZip.on('keyup', updateBankSelection)
    $addressZip.on('change', updateBankSelection)
    $schoolFieldTriggers.on('change', updateBankSelection)
}
