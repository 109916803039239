export const addOrUpdateUrlParameter = (param, value) => {
    const url = new URL(window.location.href)
    const { searchParams } = url

    searchParams.set(param, value)
    url.search = searchParams.toString()
    window.history.pushState({ path: url.href }, '', url.href)
}

export const removeUrlParameter = (param) => {
    const url = new URL(window.location.href)
    const { searchParams } = url

    searchParams.delete(param)
    url.search = searchParams.toString()
    window.history.pushState({ path: url.href }, '', url.href)
}
